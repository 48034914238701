<template>
  <div class="w-split" :style="wStyle"></div>
</template>

<script>
  export default {
    props: {
      vertical: {
        type: Boolean,
        default: false
      },
      gap: {
        type: String,
        default: '12px'
      },
      size: {
        type: String,
        default: '1px'
      },
      color: {
        type: String,
        default: '#DCDFE6'
      },
      align: {
        type: String,
        default: '0'
      },
      compute: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      wStyle: function () {
        var style = {};
        if (this.vertical) {
          // 垂直分割
          style = {
            padding: '0',
            backgroundColor: this.color,
            width: this.compute ? `calc(100% - ${this.align} - ${this.align})` : this.align,
            height: this.size,
            marginTop: this.gap,
            marginBottom: this.gap,
            marginLeft: this.compute ? this.align : 'auto',
            marginRight: this.compute ? this.align : 'auto'
          }
        } else {
          // 水平分割
          style = {
            padding: '0',
            backgroundColor: this.color,
            width: this.size,
            height: this.compute ? `calc(100% - ${this.align} - ${this.align})` : this.align,
            marginTop: this.compute ? this.align : 'auto',
            marginBottom: this.compute ? this.align : 'auto',
            marginLeft: this.gap,
            marginRight: this.gap
          }
        }
        return style;
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
